<template>
  <div>
    <v-row
      class="mb-8"
      justify="center"
    >
      <v-col cols="12">
        <h6 class="text-center text-h6">Medidas para gravação</h6>
      </v-col>
      <v-col
        cols="6"
        class="d-flex"
        style="gap: 15px"
        align="center"
      >
        <v-text-field
          v-model="product.product_print_height"
          label="Altura"
          outlined
          rounded
          dense
          hide-details
        />
        <v-text-field
          v-model="product.product_print_width"
          label="Largura"
          outlined
          rounded
          dense
          hide-details
        />
        <v-text-field
          v-model="product.product_print_measure"
          label="Medida"
          placeholder="cm | mm | M"
          outlined
          rounded
          dense
          hide-details
        />
      </v-col>
    </v-row>
    <v-row
      justify="start"
      dense
      class="ml-2"
    >
      <v-col
        cols="12"
        md="2"
      >
        <v-text-field
          v-model="filterText"
          label="Pesquisar"
          placeholder="Gravação"
          outlined
          rounded
          dense
          hide-details
        />
      </v-col>
      <v-col
        cols="12"
        md="2"
      >
        <v-btn
          @click="searchPrint"
          class="primary"
          icon
          dark
        >
          <v-icon small>fas fa-search</v-icon>
        </v-btn>
      </v-col>
    </v-row>
    <v-row>
      <v-col
        cols="12"
        md="12"
      >
        <v-subheader>Tipos de gravação</v-subheader>
        <v-card
          max-height="250"
          flat
          class="overflow-auto"
        >
          <v-list two-line>
            <v-list-item-group
              v-model="product.product_print_types"
              multiple
            >
              <template v-for="(item, i) in printTypes">
                <v-list-item
                  :key="i"
                  :value="item.id"
                  active-class="primary--text text--accent-4"
                >
                  <template v-slot:default="{ active }">
                    <v-list-item-action>
                      <v-checkbox
                        :input-value="active"
                        color="primary"
                      />
                    </v-list-item-action>

                    <v-list-item-content>
                      <v-list-item-title v-text="item.name" />
                      <v-list-item-subtitle
                        class="caption grey--text"
                        v-text="item.description"
                      />
                    </v-list-item-content>
                  </template>
                </v-list-item>
              </template>
            </v-list-item-group>
          </v-list>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>

<script>
export default {
  props: {
    product: {
      type: Object,
      required: true,
    },
  },
  data: () => ({
    printTypes: [],
    filterText: null,
  }),
  methods: {
    removeItem(print) {
      let i = 0
      this.product.product_print_types.map((item) => {
        if (item.id == print.id) {
          this.product.product_print_types.splice(i, 1)
        }
        i++
      })
    },
    addPrint(print, active, toggle) {
      console.log(print, active, toggle)
      var count = 0
      if (active == true) {
        this.product.product_print_types.map((item) => {
          if (item.id == print.id) count++
        })
        if (count == 0) {
          this.product.product_print_types.push(print)
        }
      } else {
        if (active == false) {
          this.product.product_print_types.map((item) => {
            console.log(item)
            if (item.id == print.id) count++
          })
          if (count > 0) {
            this.removeItem(print)
          }
        }
      }
    },
    getPrint() {
      this.$api
        .get('product_prints')
        .then((res) => {
          this.printTypes = res.data.data
          console.log('this.printTypes: ', this.printTypes)
        })
        .catch((err) => {
          console.log(err)
        })
        .finally(() => {
          this.printTypes.isLoading = false
        })
    },
    searchPrint() {
      this.printTypes = []
      this.$api
        .post('product_prints/filter', { name: this.filterText })
        .then((res) => {
          this.printTypes = res.data.data
          console.log(res, this.printTypes)
        })
        .catch((err) => {
          console.log(err)
        })
        .finally(() => {
          this.printTypes.isLoading = false
        })
    },
  },
  mounted() {
    this.getPrint()
  },
}
</script>
